import {useTranslation} from 'react-i18next';

import MDButton from 'shared/MDButton';
import {callApi} from 'utils/network';
import githubIcon from 'components/AppFrame/sidebarLogos/github.svg';
import MDTypography from 'shared/MDTypography';
import {Card} from '@mui/material';
import MDBox from 'shared/MDBox';

export function LoginGitHubAccount(): JSX.Element {
	const {t} = useTranslation();

	const onLogin = async () => {
		const {data} = await callApi.get(`/api/v1/auth/github/install`);
		if (data.url) {
			window.open(data.url);
		}
	};
	return (
		<>
			<MDTypography variant="h4" gutterBottom>
				{t('github_account_link_title')}
			</MDTypography>
			<Card sx={{p: 2, maxWidth: '500px'}}>
				<MDTypography>{t('github.linkReason')}</MDTypography>

				<MDBox display="flex" mt={2} justifyContent="center">
					<MDButton
						variant="contained"
						color="dark"
						startIcon={
							<img width="20" height="20" src={githubIcon} />
						}
						onClick={onLogin}
					>
						{t('github.account_link')}
					</MDButton>
				</MDBox>
			</Card>
		</>
	);
}
