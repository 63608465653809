import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import theme from 'assets/theme';
import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import MDBox from 'shared/MDBox';
import {useMaterialUIController} from 'shared/context';
import {AppSwitcherContext} from './AppSwitcherContext';
import {SwitcherIcon} from './SwitcherIcon';

const ITEM_HEIGHT = 48;

export enum DrCloudAppSwitcher {
	DeepBuild = 'DeepBuild',
	DeepAsset = 'DeepAsset',
}

const options = [DrCloudAppSwitcher.DeepBuild, DrCloudAppSwitcher.DeepAsset];

export const getDefaultSwitcherFromBrowserStorage = () => {
	if (localStorage.getItem('DeepAppSwitcher')) {
		return (
			(localStorage.getItem('DeepAppSwitcher') as DrCloudAppSwitcher) ||
			DrCloudAppSwitcher.DeepBuild
		);
	}

	return DrCloudAppSwitcher.DeepBuild;
};

export const AppSwitcher: React.FC = () => {
	const {appSwitcher, setAppSwitcher} = React.useContext(AppSwitcherContext);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const navigate = useNavigate();

	const handleClose = (val: DrCloudAppSwitcher) => () => {
		setAppSwitcher(val);
		navigate('/');
		localStorage.setItem('DeepAppSwitcher', val);
		setAnchorEl(null);
	};

	const [controller] = useMaterialUIController();
	const {miniSidenav} = controller;

	return (
		<MDBox
			sx={{
				borderBottom: '1px solid #2e3237',
				paddingBottom: '10px',
			}}
		>
			<MDBox
				aria-label="more"
				id="app-switcher-button"
				aria-controls={open ? 'long-menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup="true"
				onClick={handleClick}
				display="flex"
				justifyContent={miniSidenav ? 'center' : 'space-between'}
				alignItems="center"
				sx={{
					color: (theme) => theme.palette.white.main,
					cursor: 'pointer',
					mt: '20px',
				}}
			>
				{!miniSidenav && (
					<MDBox
						sx={{
							color: (theme) => theme.palette.white.main,
							fontSize: theme.typography.h5.fontSize,
							ml: 1,
							fontWeight: 600,
						}}
					>
						{appSwitcher}
					</MDBox>
				)}
				<SwitcherIcon />
			</MDBox>
			<Menu
				id="app-switcher-menu"
				MenuListProps={{
					'aria-labelledby': 'app-switcher-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose(appSwitcher)}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 4.5,
						padding: 0,
					},
				}}
			>
				{options.map((option) => (
					<MenuItem
						key={option}
						selected={option === appSwitcher}
						onClick={handleClose(option)}
						sx={{
							borderRadius: (theme) =>
								theme.borders.borderRadius.xs,

							'&.MuiMenuItem-root': {
								minWidth: '160px',
							},
							'&.MuiMenuItem-root.Mui-selected': {
								// color: (theme) => theme.palette.white.main,
								backgroundColor: '#DAEAF2',
								fontWeight: 600,
							},
						}}
					>
						{option}
					</MenuItem>
				))}
			</Menu>
		</MDBox>
	);
};
