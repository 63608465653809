import * as ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import {StyledEngineProvider, ThemeProvider} from '@mui/material';
import {Provider} from 'react-redux';
import store from 'store';
import {BrowserRouter as Router} from 'react-router-dom';
import Security from 'components/Security';
import 'styles/index.scss';
import {initAlert} from 'utils/alert';
import {
	MaterialUIControllerProvider,
	useMaterialUIController,
} from 'shared/context';
import themeDark from 'assets/theme-dark';
import theme from 'assets/theme';
import DrCloudApp from 'DrcloudAppMain';
import {SetGoogleAnalytics} from 'utils/analytics/SetGoogleAnalytics';
import {initDataDog} from 'utils/analytics/datadog';

initAlert(store);
initDataDog();

console.log(`APP ENV: ${process.env.REACT_APP_ENV}`);

const DrCloud = () => {
	const [controller] = useMaterialUIController();
	const {darkMode} = controller;

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={darkMode ? themeDark : theme}>
				<CssBaseline />
				<Provider store={store}>
					<Router>
						<SetGoogleAnalytics />
						<Security>
							<DrCloudApp />
						</Security>
					</Router>
				</Provider>
			</ThemeProvider>
		</StyledEngineProvider>
	);
};

ReactDOM.render(
	<MaterialUIControllerProvider>
		<DrCloud />
	</MaterialUIControllerProvider>,
	document.getElementById('app')
);
