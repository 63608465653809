import {datadogRum} from '@datadog/browser-rum';

export const initDataDog = () => {
	datadogRum.init({
		applicationId: '3af5a230-fc33-4696-a49f-ebf71c3288a1',
		clientToken: 'pubffb2887979e2d0f38c4e5b4d4fd09f37',
		site: 'datadoghq.com',
		service: 'Deepbits',
		env: process.env.NODE_ENV,

		// Specify a version number to identify the deployed version of your application in Datadog
		// version: '1.0.0',
		sampleRate: 100,
		premiumSampleRate: 100,
		trackInteractions: true,
		defaultPrivacyLevel: 'mask-user-input',
	});

	datadogRum.startSessionReplayRecording();
};
