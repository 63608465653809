import {CircularProgress, Box} from '@mui/material';

export default function Spinner(props: {size?: number}): JSX.Element {
	const {size = 10} = props;
	return (
		<Box display="flex" justifyContent="center">
			<CircularProgress
				style={{width: `${size}px`, height: `${size}px`}}
			/>
		</Box>
	);
}
