import React, {createContext, useState} from 'react';
import {DrCloudAppSwitcher, getDefaultSwitcherFromBrowserStorage} from '.';

const AppSwitcherContext = createContext<any>(null);

const AppSwitcherProvider: React.FC = ({children}) => {
	const [appSwitcher, setAppSwitcher] = useState(
		getDefaultSwitcherFromBrowserStorage()
	);

	const isAssetsApp = appSwitcher === DrCloudAppSwitcher.DeepAsset;

	return (
		<AppSwitcherContext.Provider
			value={{appSwitcher, setAppSwitcher, isAssetsApp}}
		>
			{children}
		</AppSwitcherContext.Provider>
	);
};

export {AppSwitcherContext, AppSwitcherProvider};
