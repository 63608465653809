import React, {
	JSXElementConstructor,
	Key,
	ReactElement,
	useEffect,
	useState,
} from 'react';
import Sidenav from 'shared/Sidenav';

import 'utils/i18n/i18n';
import userStore from 'store/user';
import brandWhite from 'assets/img/logo-white@500w.png';
import brandDark from 'assets/img/logo@500w.png';
import brandWhiteMini from 'assets/img/logo-square-white@500w.png';
import brandDarkMini from 'assets/img/logo-square@500w.png';
import {setMiniSidenav, useMaterialUIController} from 'shared/context';
import {useDrCloudRoutes} from 'components/AppFrame/useDrcloudRoutes';
import {Navigate, Route, Routes, useMatch, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import DashboardLayout from 'shared/LayoutContainers/DashboardLayout';

import LoadingScreen from 'components/LoadingScreen';

import Login from 'pages/Login';
import PageLayout from 'shared/LayoutContainers/PageLayout';

import GlobalNotification from 'components/GlobalNotification/GlobalNotification';
import Alert from 'components/Alert';
import {useAuth0} from '@auth0/auth0-react';
import {setAuthTokenGetter} from 'utils/network';
import LandingRedirect from 'pages/LandingRedirect';
import SiteEntryPointProvider, {
	SiteEntryPoint,
} from 'components/SiteEntryPoint';
import {ApiContextProvider} from 'components/ApiContext';
import {AppSwitcher} from 'components/AppFrame/components/AppSwitcher';
import {
	AppSwitcherContext,
	AppSwitcherProvider,
} from 'components/AppFrame/components/AppSwitcher/AppSwitcherContext';
const JiraIntegrationSuccess = React.lazy(
	() => import('pages/Settings/Jira/Success')
);
const GitHubAppInstallSuccess = React.lazy(
	() => import('pages/Settings/Github/InstallSuccess')
);
const GitHubAppInstallFailed = React.lazy(
	() => import('pages/Settings/Github/InstallFailed')
);
const GitHubAppOauthSuccess = React.lazy(
	() => import('pages/Settings/Github/OauthSuccess')
);

const EmailVerify = React.lazy(() => import('pages/Login/EmailVerify'));

const DrCloudApp = () => {
	const routes = useDrCloudRoutes();
	const [controller] = useMaterialUIController();
	const {layout} = controller;
	const dispatch = useDispatch();

	const getRoutes = (allRoutes: any[]): any =>
		allRoutes.map(
			(route: {
				collapse: any;
				route: string;
				component: ReactElement<
					any,
					string | JSXElementConstructor<any>
				>;
				key: Key;
			}) => {
				if (route.collapse) {
					return getRoutes(route.collapse);
				}
				if (route.route) {
					return (
						<Route
							path={route.route}
							element={
								<DashboardLayout>
									{route.component}
								</DashboardLayout>
							}
							key={route.key}
						/>
					);
				}

				return null;
			}
		);

	const {
		getAccessTokenSilently,
		loginWithRedirect,
		user,
		isAuthenticated,
		isLoading,
	} = useAuth0();

	const navigate = useNavigate();
	const isLoginPage = useMatch({
		path: '/login',
	});
	useEffect(() => {
		if (!isLoading) {
			if (!isAuthenticated) {
				loginWithRedirect({
					appState: {
						returnTo: window.location.pathname.concat(
							window.location.search
						),
					},
				});
				return;
			}

			dispatch(userStore.actions.set(user));
			setAuthTokenGetter(getAccessTokenSilently);

			if (!user?.email_verified && !isLoginPage) {
				navigate('/email-verify', {
					state: {
						redirectTo: window.location.pathname,
					},
				});
			}
		}
	}, [isAuthenticated, isLoading]);

	useEffect(() => {
		document.title = 'Deepbits - Software Bill of Materials';
	}, []);

	const routesToRender = getRoutes(routes);

	return (
		<SiteEntryPointProvider value={SiteEntryPoint.InApp}>
			<ApiContextProvider value={SiteEntryPoint.InApp}>
				<AppSwitcherProvider>
					{layout === 'dashboard' && isAuthenticated && (
						<SidenavWrapper />
					)}
					<LoadingScreen shouldAnimate={true} finished={!isLoading} />
					<Alert />
					<GlobalNotification />

					<React.Suspense fallback={<></>}>
						<Routes>
							<Route
								path={'/login'}
								element={
									<PageLayout>
										<Login />
									</PageLayout>
								}
							/>
							<Route
								path={'/email-verify'}
								element={
									<PageLayout>
										<EmailVerify />
									</PageLayout>
								}
							/>
							<Route
								path={'/settings/jira/succcess'}
								element={
									<PageLayout>
										<JiraIntegrationSuccess />
									</PageLayout>
								}
							/>
							<Route
								path={'/cloud/github/account/install/success'}
								element={
									<PageLayout>
										<GitHubAppInstallSuccess />
									</PageLayout>
								}
							/>
							<Route
								path={'/cloud/github/account/fail'}
								element={
									<PageLayout>
										<GitHubAppInstallFailed />
									</PageLayout>
								}
							/>
							<Route
								path={'/cloud/github/account/success'}
								element={
									<PageLayout>
										<GitHubAppOauthSuccess />
									</PageLayout>
								}
							/>
							<Route
								path={'/landing'}
								element={<LandingRedirect />}
							/>
							{isAuthenticated && routesToRender}
							{isAuthenticated && (
								<Route
									path="/"
									element={<Navigate to="/home" />}
								/>
							)}
						</Routes>
					</React.Suspense>
				</AppSwitcherProvider>
			</ApiContextProvider>
		</SiteEntryPointProvider>
	);
};

const SidenavWrapper = () => {
	const routes = useDrCloudRoutes();
	const [controller, dispatchMD] = useMaterialUIController();
	const {
		miniSidenav,
		sidenavColor,
		transparentSidenav,
		whiteSidenav,
		darkMode,
	} = controller;
	const [onMouseEnter, setOnMouseEnter] = useState(false);

	const handleOnMouseEnter = () => {
		if (miniSidenav && !onMouseEnter) {
			setMiniSidenav(dispatchMD, false);
			setOnMouseEnter(true);
		}
	};

	// Close sidenav when mouse leave mini sidenav
	const handleOnMouseLeave = () => {
		if (onMouseEnter) {
			setMiniSidenav(dispatchMD, true);
			setOnMouseEnter(false);
		}
	};

	const {appSwitcher} = React.useContext(AppSwitcherContext);
	const routesToRender = routes.filter((x) => {
		if (!x.name) {
			return false;
		}

		if (x.apps) {
			return x.apps.includes(appSwitcher);
		}

		return true;
	});

	return (
		<Sidenav
			switcher={<AppSwitcher />}
			color={sidenavColor}
			brand={
				(transparentSidenav && !darkMode) || whiteSidenav
					? miniSidenav
						? brandDarkMini
						: brandDark
					: miniSidenav
					? brandWhiteMini
					: brandWhite
			}
			routes={routesToRender}
			onMouseEnter={handleOnMouseEnter}
			onMouseLeave={handleOnMouseLeave}
			onMouseEnterActive={onMouseEnter}
			setOnMouseEnter={setOnMouseEnter}
		/>
	);
};

export default DrCloudApp;
