import {Auth0Provider} from '@auth0/auth0-react';
import {AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN} from 'constant';
import {useNavigate} from 'react-router-dom';

const Security: React.FC = ({children}) => {
	const navigate = useNavigate();

	const onRedirectCallback = (appState) => {
		navigate((appState && appState.returnTo) || window.location.pathname);
	};

	return (
		<Auth0Provider
			onRedirectCallback={onRedirectCallback}
			domain={AUTH0_DOMAIN as string}
			clientId={AUTH0_CLIENT_ID as string}
			redirectUri={window.location.origin}
			audience={AUTH0_AUDIENCE}
			scope={'profile all:drcloud'}
		>
			{children}
		</Auth0Provider>
	);
};

export default Security;
