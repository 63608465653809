import React from 'react';

export const SwitcherIcon: React.FC = () => {
	return (
		<svg
			width="16"
			height="14"
			viewBox="0 0 16 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 4.5H14.5L11.5 1"
				stroke="#DAEAF2"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.5 9.5L1 9.5L4 13"
				stroke="#DAEAF2"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
