import {callApi} from 'utils/network';
import Spinner from 'components/Spinner';
import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import MDBox from 'shared/MDBox';

const LandingRedirect = (): JSX.Element => {
	const navigate = useNavigate();
	const location = useLocation();
	const shouldOpenRedeemDialog = true;

	useEffect(() => {
		callApi.get(`/api/v1/user`).finally(() => {
			navigate(
				(location.state as {redirectTo: string})?.redirectTo || '/',
				{
					state: {
						shouldOpenRedeemDialog,
					},
				}
			);
		});
	}, []);

	return (
		<MDBox style={{marginTop: '50vh', transform: 'translateY(-50%)'}}>
			<Spinner size={60} />
		</MDBox>
	);
};

export default LandingRedirect;
