import {Paper} from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

export interface StateProps {}
export interface ActionProps {}
export interface OwnProps {}

function LoginPage(): JSX.Element {
	// const [isMarketPlace, searchParams] = useIsMarketplaceRegistration();

	// const [showAwsAccountRegistedError, setShowAwsAccountRegistedError] =
	// 	useState(false);

	const navigate = useNavigate();

	// useEffect(() => {
	// 	if (isMarketPlace) {
	// 		setMarketplaceToken(searchParams.get('token') as string);
	// 	}
	// }, []);

	// const onSuccess = () => {
	// 	trackEvent({
	// 		name: 'marketplace-registration-success',
	// 		params: {
	// 			category: 'marketplace-registration',
	// 		},
	// 	});
	// 	navigate('/');
	// };

	// useEffect(() => {
	// 	marketplaceCheck(setShowAwsAccountRegistedError, onSuccess);
	// }, []);

	useEffect(() => {
		navigate('/');
	}, []);

	return (
		<Paper>
			<LoadingScreen />
			{/* {!showAwsAccountRegistedError && <LoadingScreen />}
			<AwsMarketplaceRegistedAlert
				open={showAwsAccountRegistedError}
				onClose={() => {
					navigate('/logout');
				}}
			/> */}
		</Paper>
	);
}

export default LoginPage;
