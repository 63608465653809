/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import {Theme} from '@mui/material/styles';

// Material Dashboard 2 PRO React TS components
import MDBox from 'shared/MDBox';
import MDTypography from 'shared/MDTypography';
// import githubIconBlack from 'components/AppFrame/sidebarLogos/githubBlack.svg';

// Material Dashboard 2 PRO React context
import {useMaterialUIController} from 'shared/context';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
// import jira from './icons/jira.svg';

const isSocialLogin = (sub: string) => !/^auth0/.test(sub);

function Sidenav(): JSX.Element {
	const {t} = useTranslation();
	const [controller] = useMaterialUIController();
	const {darkMode} = controller;
	const user = useSelector((state: AppState) => state.user);

	const sidenavItems = [
		{
			icon: 'notifications',
			label: t('notificationText'),
			href: 'notifications',
		},
		{icon: 'person', label: t('profile'), href: 'profile'},
		// {icon: 'dark_mode', label: t('theme'), href: 'theme'},
		{icon: 'code', label: 'API', href: 'api'},
		// {icon: <img src={jira} />, label: 'Jira Integration', href: 'jira'},
		// {
		// 	icon: <img width={24} height={24} src={githubIconBlack} />,
		// 	label: t('github_account_title'),
		// 	href: 'github',
		// },
		{icon: 'delete', label: t('deleteAccount'), href: 'delete-account'},
	];

	if (!isSocialLogin(user.sub)) {
		sidenavItems.splice(2, 0, {
			icon: 'lock',
			label: t('changePassword'),
			href: 'change-password',
		});
	}

	const renderSidenavItems = sidenavItems.map(({icon, label, href}, key) => {
		const itemKey = `item-${key}`;
		const link = `/settings/${href}`;

		return (
			<MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
				<MDTypography
					component={Link}
					to={link}
					variant="button"
					fontWeight="regular"
					textTransform="capitalize"
					sx={({
						borders: {borderRadius},
						functions: {pxToRem},
						palette: {light},
						transitions,
					}: Theme) => ({
						display: 'flex',
						alignItems: 'center',
						borderRadius: borderRadius.md,
						padding: `${pxToRem(10)} ${pxToRem(16)}`,
						transition: transitions.create('background-color', {
							easing: transitions.easing.easeInOut,
							duration: transitions.duration.shorter,
						}),

						'&:hover': {
							backgroundColor: light.main,
						},
					})}
				>
					<MDBox
						mr={1.5}
						lineHeight={1}
						color={darkMode ? 'white' : 'dark'}
					>
						{typeof icon === 'string' ? (
							<Icon fontSize="small">{icon}</Icon>
						) : (
							icon
						)}
					</MDBox>
					{label}
				</MDTypography>
			</MDBox>
		);
	});

	return (
		<Card
			sx={{
				borderRadius: ({borders: {borderRadius}}) => borderRadius.lg,
				position: 'sticky',
				top: '1%',
			}}
		>
			<MDBox
				component="ul"
				display="flex"
				flexDirection="column"
				p={2}
				m={0}
				sx={{listStyle: 'none'}}
			>
				{renderSidenavItems}
			</MDBox>
		</Card>
	);
}

export default Sidenav;
