import {List, ListItem, ListItemText} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Link} from 'react-router-dom';
import MDBox from 'shared/MDBox';
import MDButton from 'shared/MDButton';
import MDTypography from 'shared/MDTypography';

interface Props {
	onClose: () => void;
	projects?: any[];
	registries?: any[];
}

function DeletionFailedDialog(props: Props): JSX.Element {
	const {onClose, projects, registries} = props;

	return (
		<Dialog
			fullWidth
			maxWidth="sm"
			open={true}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle>{'Deletion Blocked'}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					The deletion is currently not possible as the resource is
					being utilized by below projects and registries.
				</DialogContentText>
				<MDBox mt={4}>
					{!!projects?.length && (
						<MDBox>
							<MDTypography
								variant="h6"
								fontWeight="medium"
								gutterBottom
							>
								Projects
							</MDTypography>
							<List>
								{projects.map((p) => (
									<ListItemLink
										to={`/project/${p._id}`}
										key={p._id}
									>
										<ListItemText
											primary={p.name}
											color="text"
										/>
									</ListItemLink>
								))}
							</List>
						</MDBox>
					)}
					<MDBox mt={4}>
						{!!registries?.length && (
							<MDBox>
								<MDTypography
									variant="h6"
									fontWeight="medium"
									gutterBottom
								>
									Registries
								</MDTypography>
								<List>
									{registries.map((r) => (
										<ListItemLink
											to={`/registry/${r._id}`}
											key={r._id}
										>
											<ListItemText
												primary={r.name}
												color="text"
											/>
										</ListItemLink>
									))}
								</List>
							</MDBox>
						)}
					</MDBox>
				</MDBox>
			</DialogContent>
			<DialogActions>
				<MDButton onClick={onClose} color="secondary" size="small">
					Close
				</MDButton>
			</DialogActions>
		</Dialog>
	);
}

const ListItemLink = (props): JSX.Element => {
	return <ListItem button component={Link} {...props} />;
};

export default DeletionFailedDialog;
