import {useAuth0} from '@auth0/auth0-react';
import LoadingScreen from 'components/LoadingScreen';
import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

const Logout = () => {
	const {logout} = useAuth0();
	const navigate = useNavigate();

	const location = useLocation();
	const {logoutClicked} = (location as any).state || {};

	useEffect(() => {
		if (logoutClicked) {
			logout({returnTo: window.location.origin});
		} else {
			// Special usage to handle an Auth0 bug
			// Auth0 will redirect to the URL of the page from which the user initiated the logout process, right after a new login is made.
			// In this case: /logout.
			// Navigate to index to avoid this issue.
			navigate('/');
		}
	}, [logoutClicked]);

	return <LoadingScreen shouldAnimate={true} finished={false} />;
};

export default Logout;
