// Material Dashboard 2 PRO React TS Helper Functions
import pxToRem from 'assets/theme-dark/functions/pxToRem';

// types
type Types = any;

const sidenav: Types = {
	styleOverrides: {
		root: {
			width: pxToRem(250),
			whiteSpace: 'nowrap',
			border: 'none',
		},

		paper: {
			width: pxToRem(250),
			border: 'none',
		},

		paperAnchorDockedLeft: {
			borderRight: 'none',
		},
	},
};

export default sidenav;
