import {CircularProgress, Fade, Grid} from '@mui/material';
import DeepbitsLogo from '/src/assets/img/logo.svg';

const LoadingScreen = ({
	shouldAnimate = false,
	finished,
}: {
	shouldAnimate?: boolean;
	finished?: boolean;
}): JSX.Element => {
	const splashContent = (
		<Grid
			container
			direction="column"
			justifyContent="center"
			alignItems="center"
			sx={(theme) => ({
				minHeight: '100vh',
				minWidth: '100vw',
				position: 'fixed',
				left: 0,
				top: 0,
				zIndex: 9999,
				backgroundColor: theme.palette.background.default,
			})}
		>
			<Grid
				container
				direction="column"
				justifyContent="center"
				alignItems="center"
				style={{transform: 'translateY(-30%)'}}
			>
				<img
					src={DeepbitsLogo}
					alt={'Deepbits'}
					width="20%"
					style={{minWidth: '200px', maxWidth: '360px'}}
				/>
				<CircularProgress size={40} sx={{mt: 4}} color="info" />
			</Grid>
		</Grid>
	);

	return shouldAnimate ? (
		<Fade
			in={!finished}
			timeout={!finished ? 880 : 400}
			unmountOnExit={true}
		>
			{splashContent}
		</Fade>
	) : (
		splashContent
	);
};

export default LoadingScreen;
